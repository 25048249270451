// 
// user.js
// Use this to write your custom JS
//

import '@fortawesome/fontawesome-free/js/all.js';


// var printables = document.querySelectorAll('[data-print]')

// function PrintMe(elem) {

//     var mywindow = window.open('', 'PRINT', 'height=400,width=600');

//     mywindow.document.write('<html><head><title>' + document.title  + '</title>');
//     mywindow.document.write('</head><body >');
//     mywindow.document.write('<h1>' + document.title  + '</h1>');
//     mywindow.document.write(element.innerHTML);
//     mywindow.document.write('</body></html>');

//     mywindow.document.close(); // necessary for IE >= 10
//     mywindow.focus(); // necessary for IE >= 10*/

//     mywindow.print();
//     mywindow.close();

//     return true;
    
// }


// printables.forEach(element => {
//     const elementOptions = element.dataset.print ? JSON.parse(element.dataset.print) : {};
    
//     if (!element || !elementOptions.hasOwnProperty('printButtonID')) return;
//     const printButton = document.getElementById(elementOptions.printButtonID)
//     document.addEventListener('click', PrintMe);
// });

function printDiv() {
    var printContents = document.querySelector('[data-print]').innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}

var printButton = document.querySelector('#printTerms')

printButton.addEventListener('click', (event) => {
    printDiv();
});